<template>
    <div>
        <OrganizationClientPlanningLine
            :plannings="plannings.filter(x => $moment(x.planning.dateBegin) > $moment(Date.now()))" title="Futurs" />
        <OrganizationClientPlanningLine
            :plannings="plannings.filter(x => $moment(x.planning.dateBegin) < $moment(Date.now())).sort(x => $moment(x.planning.dateBegin))"
            title="Historique" />
    </div>
</template>

<script>
import datehelper from '@/helpers/datehelper'
import OrganizationClientPlanningLine from "@/components/PublicOrganization/OrganizationClientPlanningLine";

export default {
    name: "OrganizationClientPlannings",
    components: {
        OrganizationClientPlanningLine,
    },
    computed: {
        organizationUrl() {
            return this.$route.params.organizationUrl;
        },
    },
    async mounted() {
        await this.retrieveAll();
    },
    data() {
        return {
            client: null,
            plannings: [],
        };
    },
    methods: {
        getLink(date) {
            const monday = datehelper.getMonday(date);

            const path = this.$helpers.router.getOrganizationLink();

            return path + "?date=" + encodeURIComponent(monday.toISOString().substr(0, 10));
        },
        async retrieveAll() {
            try {
                const client = await this.$store
                    .dispatch("publicorganizationauth/fetchClient")
                    .catch(() => { });

                this.client = client;

                if (!this.client) {
                    this.$router.push(this.$helpers.router.getOrganizationLink("login"));
                    return;
                }

                const plannings =
                    await this.$services.publicorganization.getClientPlannings(
                        this.organizationUrl
                    );

                this.plannings = plannings.data;

                console.dir(this.plannings);
            } catch (error) {
                console.dir(error);
                this.$helpers.snackbar.handleError(error);
            }
        },
    },
};
</script>