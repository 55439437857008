<template>
    <v-container fluid v-if="plannings.length > 0">
        <v-row no-gutters class="mb-2">
            <v-col class="text-h6">{{ title }}</v-col>
        </v-row>
        <v-row no-gutters v-for="planning in plannings" :key="planning.id" class="ml-2">
            <v-col>
                <v-btn plain :to="getLink(planning.planning.dateBegin)">
                    <v-icon left small>mdi-eye</v-icon>
                    {{ $moment(planning.planning.dateBegin).format("DD/MM/YYYY") }}
                    -
                    {{ planning.planning.lesson.activityName ? planning.planning.lesson.activityName :
        planning.planning.lesson.name }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import datehelper from '@/helpers/datehelper'

export default {
    name: "OrganizationClientPlannings",
    components: {
    },
    props: {
        plannings: [],
        client: null,
        title: String,
    },
    computed: {
        organizationUrl() {
            return this.$route.params.organizationUrl;
        },
    },
    async mounted() {
        // await this.retrieveAll();
    },
    data() {
        return {
            // client: null,
            // plannings: [],
        };
    },
    methods: {
        getLink(date) {
            const monday = datehelper.getMonday(date);

            const path = this.$helpers.router.getOrganizationLink();

            return path + "?date=" + encodeURIComponent(monday.toISOString().substr(0, 10));
        },
        // async retrieveAll() {
        //     try {
        //         const client = await this.$store
        //             .dispatch("publicorganizationauth/fetchClient")
        //             .catch(() => { });

        //         this.client = client;

        //         if (!this.client) {
        //             this.$router.push(this.$helpers.router.getOrganizationLink("login"));
        //             return;
        //         }

        //         const plannings =
        //             await this.$services.publicorganization.getClientPlannings(
        //                 this.organizationUrl
        //             );

        //         this.plannings = plannings.data;

        //         console.dir(this.plannings);
        //     } catch (error) {
        //         console.dir(error);
        //         this.$helpers.snackbar.handleError(error);
        //     }
        // },
    },
};
</script>