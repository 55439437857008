<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8" lg="6" xl="6">
        <v-btn :to="`/${organizationUrl}`" color="primary" plain>
          <v-icon left>mdi-arrow-left</v-icon>Revenir au planning
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8" lg="6" xl="6">
        <v-card>
          <v-card-title>Mon compte</v-card-title>
          <v-card-text>
            <!-- {{ client }} -->
            <v-list v-if="client">
              <v-list-item>
                <v-list-item-title>{{ client.firstName }} {{ client.lastName }} ({{
          client.login
        }})</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>{{ client.email }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>

            <v-tabs v-model="tab" fixed-tabs show-arrows>
              <v-tab to="#memberships" key="memberships">
                <v-icon small left>mdi-tag</v-icon>
                Mes abonnements
              </v-tab>
              <v-tab to="#plannings" key="plannings">
                <v-icon small left>mdi-calendar</v-icon>Mes cours
              </v-tab>
            </v-tabs>
            <!-- Mes abonnements

            <v-btn class="ml-2" @click="chooseMembershipType()" color="primary">
              <v-icon left>mdi-plus</v-icon>
              Ajouter
            </v-btn> -->

          </v-card-title>
          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item value="memberships">
                <v-btn class="d-flex justify-end" @click="chooseMembershipType()" color="primary">
                  <v-icon left>mdi-plus</v-icon>
                  Nouvel abonnement
                </v-btn>
                <v-list>
                  <v-list-item v-for="membership in memberships" :key="membership.id">
                    <v-list-item-title>
                      <v-container>
                        <v-row>
                          <v-col class="shrink text-center" md="2" sm="4">
                            <p>
                              <PaymentStatusChip :payment="membership.payment" />
                            </p>
                            <p class="text-caption" v-if="membership.priceVatIncluded">
                              {{ membership.priceVatIncluded }} €
                            </p>
                          </v-col>
                          <v-col>
                            <p class="font-weight-bold">
                              {{ membership.membership_type.name }}
                            </p>
                            <p v-if="membership.dateBegin || membership.dateEnd" class="text-caption">
                              {{
          $helpers.date.getDateDisplay(membership.dateBegin)
        }}
                              <v-icon small v-if="membership.dateEnd">mdi-chevron-right</v-icon>
                              {{ $helpers.date.getDateDisplay(membership.dateEnd) }}
                            </p>
                          </v-col>
                          <v-col class="grow text-right">
                            <UsedUnitsChip :usedUnits="Number(membership.totalUnit)"
                              :totalUnits="Number(membership.membership_type.unit)" />
                          </v-col></v-row></v-container></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-tab-item>

              <v-tab-item value="plannings">
                <OrganizationClientPlannings />
              </v-tab-item>

            </v-tabs-items>


          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <MembershipTypeSelection ref="membershipTypeSelection" @onSaveClicked="membershipTypeSelected" />
  </v-container>
</template>

<script>
import UsedUnitsChip from "@/components/Admin/Unit/UsedUnitsChip";
// import PlusButton from "@/components/Public/HelpCenter/Components/PlusButton.vue";
import MembershipTypeSelection from "@/components/PublicOrganization/MembershipTypeSelection";
import OrganizationClientPlannings from "@/components/PublicOrganization/OrganizationClientPlannings";
import PaymentStatusChip from "@/components/Admin/Payment/PaymentStatusChip";

export default {
  name: "OrganizationClientAccount",
  components: {
    UsedUnitsChip,
    // PlusButton,
    MembershipTypeSelection,
    PaymentStatusChip,
    OrganizationClientPlannings,
  },
  data() {
    return {
      client: null,
      memberships: [],
      tab: 'memberships'
    };
  },
  computed: {
    // client() {
    //   return this.$store.state.publicorganizationauth.client;
    // },
    organizationUrl() {
      return this.$route.params.organizationUrl;
    },
  },
  created() {
    // if (!this.client) {
    //   this.$helpers.router.push("/" + this.organizationUrl);
    // }
  },
  async mounted() {
    await this.retrieveAll();
  },
  methods: {
    async chooseMembershipType() {
      try {
        const client = await this.$store
          .dispatch("publicorganizationauth/fetchClient")
          .catch(() => { });

        if (client == null) {
          this.$router.push({
            name: "OrganizationLogin",
            params: {
              organizationUrl: this.organizationUrl,
            },
            query: {
              next: this.$route.fullPath,
            },
          });
          return;
        }

        this.$refs.membershipTypeSelection.open(null);
      } catch (error) {
        console.dir(error);
      }
    },
    async membershipTypeSelected(payload) {
      try {
        const membershipType = payload.membershipType;
        const paymentTypeId = payload.paymentTypeId;

        const { data } =
          await this.$services.publicorganization.payMembershipType(
            this.organizationUrl,
            membershipType.id,
            paymentTypeId
          );

        if (data.paymentUrl) {
          window.location.href = data.paymentUrl;
        } else {
          window.location.reload();
        }
      } catch (e) {
        this.$helpers.snackbar.handleError(e);
      }
      // this.$emit("planningChanged", registrationPlanning.data);
    },
    async retrieveAll() {
      try {
        const client = await this.$store
          .dispatch("publicorganizationauth/fetchClient")
          .catch(() => { });

        this.client = client;

        if (!this.client) {
          this.$router.push(this.$helpers.router.getOrganizationLink("login"));
          return;
        }

        const memberships =
          await this.$services.publicorganization.getClientMemberships(
            this.organizationUrl
          );

        this.memberships = memberships.data;

        console.dir(this.memberships);
      } catch (error) {
        console.dir(error);
        this.$helpers.snackbar.handleError(error);
      }
    },
  },
};
</script>