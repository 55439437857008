<template>
  <v-container>
    <v-row>
      <v-dialog
        ref="dialog"
        v-model="dialog"
        :return-value.sync="membershipType"
        persistent
        max-width="800px"
        @keydown.esc="close()"
        :fullscreen="$helpers.display.mobileDialog"
        :hide-overlay="$helpers.display.mobileDialog"
        transition="dialog-bottom-transition"
      >
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-spacer />

            <v-stepper-step :complete="step > 1" step="1">
              Choix de l'abonnement
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="2"> Choix du paiement </v-stepper-step>

            <v-spacer />
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-sheet>
                <v-radio-group
                  v-model="membershipType"
                  v-if="membershipTypes && membershipTypes.length > 0"
                >
                  <v-container
                    v-for="m in membershipTypes"
                    :key="m.id"
                    link
                    :value="m"
                    @click="membershipType = m"
                    class="pointer"
                    fluid
                  >
                    <v-row align="center" no-gutters>
                      <v-col class="shrink">
                        <v-radio :value="m"></v-radio>
                      </v-col>
                      <v-col class="grow">
                        {{ m.name }}
                      </v-col>
                      <v-col class="grow text-right">
                        <v-chip text-color="white" color="orange">
                          <span v-if="m.unit">{{ m.unit }} unités</span>
                          <span v-else>unités illimitées</span></v-chip
                        >
                      </v-col>
                      <v-col cols="2" class="text-right">
                        <span v-if="m.priceVatIncluded">
                          {{ m.priceVatIncluded }} €</span
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-radio-group>
                <div v-else>
                  Aucun abonnement n'est disponible pour le moment.
                </div>
              </v-sheet>

              <v-container>
                <v-row align="end" justify="end">
                  <v-btn @click="close" color="light-grey"> annuler </v-btn>
                  <v-btn
                    color="primary"
                    @click="step = 2"
                    v-if="membershipTypes && membershipTypes.length > 0"
                    :disabled="!membershipType"
                    class="ml-2"
                  >
                    Continuer
                    <v-icon class="ml-2">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-sheet v-if="membershipType">
                <div v-if="membershipType.priceVatIncluded">
                  {{ membershipType.name }}
                  <span v-if="membershipType.priceVatIncluded"
                    >{{ membershipType.priceVatIncluded }} €</span
                  >
                  <v-radio-group
                    v-model="paymentTypeId"
                    v-if="
                      paymentTypes.payment_types &&
                      paymentTypes.payment_types.length > 0
                    "
                  >
                    <v-container
                      v-for="pt in paymentTypes.mollie_methods"
                      :key="'mollie.' + pt.id"
                      link
                      :value="pt.id"
                      @click="paymentTypeId = 'mollie.' + pt.id"
                      class="pointer"
                      fluid
                    >
                      <v-row align="center" no-gutters>
                        <v-col class="shrink">
                          <v-radio :value="'mollie.' + pt.id"></v-radio>
                        </v-col>
                        <v-col class="shrink">
                          <v-img :src="pt.image" />
                        </v-col>
                        <v-col class="grow ml-2 font-weight-bold">
                          {{ pt.description }}
                        </v-col>
                        <v-col class="grow text-right">
                          <span v-if="pt.id == 'bancontact'">
                            Payer en toute sécurité vers un compte Belge avec
                            Bancontact
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>

                    <div
                      v-for="pt in paymentTypes.payment_types"
                      :key="pt.id"
                      link
                      :value="pt.id"
                      @click="paymentTypeId = pt.id"
                      class="pointer"
                    >
                      <v-container v-if="pt.id != 'mollie'" fluid>
                        <v-row align="center" no-gutters>
                          <v-col class="shrink">
                            <v-radio :value="pt.id"></v-radio>
                          </v-col>
                          <v-col class="grow font-weight-bold">
                            {{ getPaymentTypeLabel(pt) }}
                          </v-col>
                          <v-col class="grow text-right">
                            {{ getPaymentTypeDescription(pt) }}
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                  </v-radio-group>
                  <div v-else>
                    Aucune méthode de paiement n'est disponible pour le moment.
                  </div>
                </div>
                <div v-else>Abonnement gratuit, vous n'avez rien à payer.</div>
              </v-sheet>

              <v-container>
                <v-row align="end" justify="end">
                  <v-btn @click="close" color="light-grey"> annuler </v-btn>
                  <v-btn
                    ref="saveButton"
                    :loading="loading"
                    color="primary"
                    @click="saveAndClose"
                    class="ml-2"
                  >
                    confirmer
                    <v-icon class="ml-2">mdi-check</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <!-- <v-card>
          <v-card-title> Choississez un abonnement </v-card-title>
          <v-card-text>
            <v-radio-group
              v-model="membershipType"
              v-if="membershipTypes && membershipTypes.length > 0"
            >
              <v-list>
                <v-list-item
                  v-for="m in membershipTypes"
                  :key="m.id"
                  link
                  :value="m"
                  @click="membershipType = m"
                >
                  <v-list-item-title>
                    <v-container>
                      <v-row align="center">
                        <v-col class="shrink">
                          <v-radio :value="m"></v-radio>
                        </v-col>
                        <v-col class="shrink">
                          {{ m.name }}
                        </v-col>
                        <v-col class="grow text-right">
                          <v-chip text-color="white" color="orange">
                            <span v-if="m.unit">{{ m.unit }} unités</span>
                            <span v-else>unités illimitées</span></v-chip
                          >
                        </v-col>
                        <v-col cols="2" class="text-right">
                          <span v-if="m.priceVatIncluded">
                            {{ m.priceVatIncluded }} €</span
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item-title></v-list-item
                ></v-list
              >
            </v-radio-group>
            <div v-else>Aucun abonnement n'est disponible pour le moment.</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            
            <v-btn @click="close" color="light-grey"> annuler </v-btn>
            <v-btn
              ref="saveButton"
              :loading="loading"
              color="primary"
              @click="saveAndClose"
              v-if="membershipTypes && membershipTypes.length > 0"
            >
              confirmer
            </v-btn>
          </v-card-actions>
        </v-card> -->
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      membershipType: null,
      membershipTypes: [],
      paymentTypeId: null,
      paymentTypes: [],
      dialog: false,
      loading: false,
      planningId: null,
      step: 1,
    };
  },
  computed: {
    organizationUrl() {
      return this.$route.params.organizationUrl;
    },
  },
  methods: {
    getPaymentTypeLabel(paymentType) {
      switch (paymentType.id) {
        case "cash":
          return "Payer en espèces";
        case "sepa":
          return "Virement bancaire";
        default:
          return null;
      }
    },
    getPaymentTypeDescription(paymentType) {
      switch (paymentType.id) {
        case "cash":
          return "Payer plus tard sur place";
        case "sepa":
          return "Effectuer un virement sur notre compte bancaire";
        default:
          return null;
      }
    },
    close() {
      this.dialog = false;
      this.$emit("onCloseClicked");
    },
    async open(planningId) {
      this.step = 1;

      this.planningId = planningId;

      await this.retrieveMembershipTypes();

      await this.retrievePaymentTypes();

      if (this.membershipTypes.length == 1) {
        this.membershipType = this.membershipTypes[0];
      }

      if (this.paymentTypes.length == 1) {
        if (this.paymentTypes[0].id != "mollie") {
          this.paymentTypeId = this.paymentTypes[0].id;
        }
      }

      console.dir(this.paymentTypes);

      this.dialog = true;
    },
    async retrieveMembershipTypes() {
      const membershipTypes = await this.$services.publicorganization.getMembershipTypes(
        this.organizationUrl,
        this.planningId
      );

      this.membershipTypes = membershipTypes.data;
    },
    async retrievePaymentTypes() {
      const paymentTypes = await this.$services.publicorganization.getPaymentTypes(
        this.organizationUrl,
        this.planningId
      );

      this.paymentTypes = paymentTypes.data;
    },
    async saveAndClose() {
      try {
        this.loading = true;

        // if (!this.$refs.form.validate()) return;

        this.$emit("onSaveClicked", {
          membershipType: this.membershipType,
          paymentTypeId: this.paymentTypeId,
        });
        this.dialog = false;
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>